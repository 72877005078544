
import { computed, ref } from "vue";
import AddressTypesSelect from "@/components/catalogs-select/AddressTypesSelect.vue";
import LocationsSelect from "@/components/catalogs-select/LocationsSelect.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";

export default {
  name: "AddressSectionGC",
  components: {
    TableBasicSelect,
    AddressTypesSelect,
    LocationsSelect,
    Form,
    Field,
    ErrorMessage,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitAddressButton = ref<HTMLElement | null>(null);
    const addressesTableData = computed(() => store.getters.COAddressOffice);
    const office = computed(() => store.getters.OfficeC);
    const editAddress = computed(() => store.getters.AOEdit);
    const location = ref();
    const header = ref([
      { column: "istreet", key: "street" },
      { column: "inumber", key: "number" },
      { column: "ilocationcode", key: "location.full_code" },
      { column: "ilocationname", key: "location.full_name" },
      { column: "izipcode", key: "zip_code" },
    ]);
    const isLoading = ref(false);
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Duplicar" as never,
        type: "DUPLICATE-2" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,
    ]);
    const form = ref({
      id: undefined,
      office_id: office.value.id,
      address_types: [],
      street: "",
      number: "",
      floor_number: "",
      zip_code: "",
      door: "",
      location_id: undefined,
      latitude: "",
      longitude: "",
    });

    const isStreetRequired = (value) => {
      if (!value) {
        return t("rstreet");
      }
      return true;
    };

    const onNewClick = () => {
      form.value.office_id = office.value.office_id;
      store.commit("setAOEdit", !editAddress.value);
    };

    const actionsButton = async (param1, param2, param3) => {
      if (param2 == "EDIT") {
        store.commit("setLoadingStatus", true);
        ApiService.get(`/api/address/${param1}`).then(({ data }) => {
          console.log(data);
          form.value.id = data.id;
          form.value.office_id = office.value.id;
          form.value.address_types = data.address_types.map((x) => x.id);
          form.value.street = data.street;
          form.value.number = data.number;
          form.value.floor_number = data.floor_number;
          form.value.door = data.door;
          form.value.location_id = data.location_id;
          form.value.zip_code = data.zip_code;
          form.value.latitude = data.latitude;
          form.value.longitude = data.longitude;
          location.value = data.location;
          store.commit("setAOEdit", true);
          store.commit("setLoadingStatus", false);
        });
      }
      if (param2 == "DUPLICATE") {
        store.commit("setLoadingStatus", true);
        const item = addressesTableData.value.filter((x) => x.id === param3);
        let payload = {
          office_id: office.value.id,
          address_types: item[0].address_types.map((x) => x.id),
          street: item[0].street,
          number: item[0].number,
          floor_number: item[0].floor_number,
          door: item[0].door,
          location_id: item[0].location_id,
          zip_code: item[0].zip_code,
          latitude: item[0].latitude,
          longitude: item[0].longitude,
        };
        ApiService.post("/api/address", payload)
          .then(({ data }) => {
            submitAddressButton.value?.removeAttribute("data-kt-indicator");
            store.commit("setCOAddress", data);
            store.commit("setLoadingStatus", false);
          })
          .catch(() => {
            submitAddressButton.value?.removeAttribute("data-kt-indicator");
            store.commit("setLoadingStatus", false);
          });
      }
      if (param2 == "REMOVE") {
        store.commit("setLoadingStatus", true);
        ApiService.delete(`/api/address/${param1}?force_delete=${param3}`)
          .then(({ data }) => {
            const index = addressesTableData.value.findIndex(
              (x) => x.id === param1
            );
            store.commit("removeCOAddress", index);
            store.commit("setLoadingStatus", false);
          })
          .catch(() => {
            submitAddressButton.value?.removeAttribute("data-kt-indicator");
            store.commit("setLoadingStatus", false);
          });
      }
    };

    const onCancel = () => {
      form.value.id = undefined;
      form.value.office_id = office.value.id;
      form.value.address_types = [];
      form.value.street = "";
      form.value.number = "";
      form.value.floor_number = "";
      form.value.door = "";
      form.value.location_id = undefined;
      form.value.zip_code = "";
      form.value.latitude = "";
      form.value.longitude = "";
      store.commit("setsaveForm", false);
      store.commit("setAOEdit", false);
    };

    const onSubmit = async () => {
      store.commit("setLoadingStatus", true);
      if (submitAddressButton.value) {
        if (office.value.id !== undefined) {
          if (office.value.name !== "") {
            if (form.value.id === undefined) {
              form.value.office_id = office.value.id;
              ApiService.post("/api/address", form.value).then(({ data }) => {
                store.commit("setCOAddress", data);
                store.commit("setLoadingStatus", false);
              });
            } else {
              ApiService.put(`/api/address/${form.value.id}`, form.value).then(
                ({ data }) => {
                  store.commit("setCOAddress", data);
                  const index = addressesTableData.value.findIndex(
                    (x) => x.id === data.id
                  );
                  store.commit("removeCOAddress", index);
                  store.commit("setLoadingStatus", false);
                }
              );
            }
            store.commit("setAEdit", false);
            onCancel();
          } else {
            ElNotification({
              title: "Error",
              message: t("rcompanyougrpoffice"),
              type: "error",
            });
            store.commit("setLoadingStatus", false);
          }
        } else {
          submitAddressButton.value.setAttribute("data-kt-indicator", "on");
          store.commit("setCOAddress", form.value);
          store.commit("setFromChild", true);
          emit("saveCompanyGroupOffice", true);
          store.commit("setsaveForm", false);
          submitAddressButton.value?.removeAttribute("data-kt-indicator");
          store.commit("setLoadingStatus", false);
        }
      }
    };

    return {
      editAddress,
      submitAddressButton,
      addressesTableData,
      header,
      isLoading,
      tableButtons,
      form,
      office,
      location,
      isStreetRequired,
      onNewClick,
      onCancel,
      onSubmit,
      actionsButton,
    };
  },
};
