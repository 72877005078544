import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bg-white rounded shadow-sm p-5 p-lg-8 mx-auto" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "col-12"
}
const _hoisted_4 = { class: "d-sm-flex flex-row align-items-center justify-content-between mb-6" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "d-sm-flex flex-row align-items-center justify-content-between mb-6" }
const _hoisted_7 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableBasicSelect = _resolveComponent("TableBasicSelect")!
  const _component_OfficeTab = _resolveComponent("OfficeTab")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.editOffice)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t("offices")), 1),
              (_ctx.canCreate)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    disabled: _ctx.companyGroupId === undefined,
                    class: "custom-btn",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onNewClick && _ctx.onNewClick(...args)), ["prevent","stop"]))
                  }, _toDisplayString(_ctx.$t("btnNew")), 9, _hoisted_5))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.search) = $event)),
                type: "text",
                class: "find-btn w-sm-350px me-2",
                placeholder: `${_ctx.$t('isearch')}`,
                onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)))
              }, null, 40, _hoisted_7), [
                [_vModelText, _ctx.search]
              ])
            ]),
            _createVNode(_component_TableBasicSelect, {
              loading: _ctx.isLoading,
              headers: _ctx.header,
              data: _ctx.filtered.length > 0 ? _ctx.filtered as any : _ctx.officesTableData,
              buttons: _ctx.tableButtons,
              total: 
            _ctx.filtered.length > 0 ? _ctx.filtered.length : _ctx.officesTableData.length
          ,
              "can-update": _ctx.canUpdate,
              "can-delete": _ctx.canDelete,
              "can-show": _ctx.canShow,
              onEventEmitButton: _ctx.actionsButton,
              "verify-delete": true
            }, null, 8, ["loading", "headers", "data", "buttons", "total", "can-update", "can-delete", "can-show", "onEventEmitButton"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.editOffice && _ctx.canShow && _ctx.canUpdate)
        ? (_openBlock(), _createBlock(_component_OfficeTab, { key: 1 }))
        : _createCommentVNode("", true)
    ])
  ]))
}