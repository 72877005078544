
import { computed, ref, defineComponent } from "vue";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form, useForm } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import AccountingPlanSelect from "@/components/catalogs-select/AccountingPlanSelect.vue";
import AddressesTE from "@/components/entities/tables-editable/AddressesTE.vue";
import store from "@/store";
import CompanyCreateView from "@/views/companies/CompaniesCU.vue";
import OfficeCreateView from "@/views/companies/OfficesCU.vue";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";
import Avatar from "@/components/avatar/avatar.vue";
import CurrencySelect from "@/components/catalogs-select/CurrencySelect.vue";
import InputBase from "@/components/Base/InputBase.vue";

interface Company {
  id: number | undefined;
  company_group_id: number | undefined;
  currency_id: number | undefined;
  entity_id: number | undefined;
  accounting_plan_id: number | undefined;
  name: string;
  lang: string;
  cif: string;
  website: string;
  logo: string;
  logodoc: string;
  fiscal_data: string;
  code: string;
}

export default defineComponent({
  name: "CompaniesSection",
  components: {
    InputBase,
    CurrencySelect,
    Avatar,
    TableBasicSelect,
    CompanyCreateView,
    AddressesTE,
    Form,
    Field,
    ErrorMessage,
    EntitySelect,
    AccountingPlanSelect,
  },
  emits: ["saveCompanyGroup"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitCompanyButton = ref<HTMLElement | null>(null);
    const companiesTableData = computed(() => store.getters.CompaniesListG);
    const officesTableData = computed(() => store.getters.OfficeCompaniesList);
    const companyGroup = computed(() => store.getters.CompanyGroup);
    const company = computed(() => store.getters.Company);
    const editCompany = computed(() => store.getters.CCEdit);
    const editOffice = computed(() => store.getters.OOEdit);
    const dataTable = computed(() => store.getters.Addresses);
    const { handleSubmit } = useForm();
    const header = ref([
      {
        column: "ientity",
        key: "entity_name",
      },
      { column: "iname", key: "name" },
      { column: "icifo", key: "cif" },
      { column: "iwebsite", key: "website" },
    ]);
    const headerOffice = ref([
      { column: "iname", key: "name" },
      { column: t("iremarks"), key: "remarks" },
      { column: t("iismain"), key: "is_main" },
    ]);
    const isLoading = ref(false);
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      /*{
        name: "Duplicar" as never,
        type: "DUPLICATE-2" as never,
        color: "primary" as never,
      } as never,*/
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,
    ]);
    const logo = ref(undefined) as any;
    const logodoc = ref(undefined) as any;
    const form = ref<Company>({
      id: undefined,
      company_group_id: companyGroup.value.id,
      entity_id: undefined,
      accounting_plan_id: undefined,
      currency_id: undefined,
      name: "",
      lang: "",
      cif: "",
      website: "",
      logo: "",
      logodoc: "",
      fiscal_data: "",
      code: "",
    }) as any;

    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "companies: create")
            .length > 0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "companies: show").length >
          0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "companies: update")
            .length > 0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "companies: delete")
            .length > 0
        );
      }
      return false;
    });

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const isCifRequired = (value) => {
      if (!value) {
        return t("rcif");
      }
      return true;
    };

    const isCodeRequired = (value) => {
      if (!value) {
        return t("rcode");
      }
      return true;
    };

    const changeImage = (event) => {
      logo.value = event[0];
    };

    const removeImage = () => {
      logo.value = "";
    };

    const changeImageDoc = (event) => {
      logodoc.value = event[0];
    };

    const removeImageDoc = () => {
      logodoc.value = "";
    };

    const onNewClick = () => {
      store.commit("setCEdit", true);
    };

    const onNewOfficeClick = () => {
      store.commit("setOEdit", !editOffice.value);
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        store.commit("setLoadingStatus", true);
        ApiService.get(`/api/companies/${param1}`).then(({ data }) => {
          const acp = [] as any;
          store.commit("emptyAddresses");
          data.accounting_plans.forEach(function (value) {
            acp.push(value.id);
          });
          const address = [] as any;
          data.addresses.forEach(function (addresVal) {
            const ati = [] as any;
            addresVal.address_types.forEach(function (atiVal) {
              ati.push(atiVal);
            });
            address.push({
              id: addresVal.id,
              street: addresVal.street,
              number: addresVal.number,
              floor_number: addresVal.floor_number,
              door: addresVal.door,
              location_id: addresVal.location_id,
              zip_code: addresVal.zip_code,
              latitude: addresVal.latitude,
              longitude: addresVal.longitude,
              address_types: ati,
            });
          });
          store.commit("setAddresses", address);
          let element = {
            id: data.id,
            company_group_id: data.company_group_id,
            entity_id: data.entity_id,
            currency_id: data.currency_id,
            accounting_plan_id: acp,
            name: data.name,
            cif: data.cif,
            website: data.website,
            logo: data.logo,
            lang: data.lang,
            logodoc: data.logodoc,
            code: data.code,
            fiscal_data: data.fiscal_data,
            addresses: address,
          };
          form.value = element;
          store.commit("setCompanyItem", element);
          store.commit("setCEdit", true);
        });
      }
      if (param2 == "DUPLICATE") {
        const item = companiesTableData.value.filter((x) => x.id === param3);
        const formData = new FormData();
        formData.append("company_group_id", item[0].company_group_id);
        formData.append("entity_id", item[0].entity_id);
        formData.append("currency_id", item[0].currency_id);
        formData.append("name", item[0].name);
        formData.append("cif", item[0].cif);
        formData.append("accounting_plan_id", item[0].accounting_plan_id);
        formData.append(
          "website",
          form.value.website ? form.value.website : ""
        );
        formData.append("lang", form.value.lang ? form.value.lang : "");
        formData.append("logo", form.value.logo);
        formData.append("logodoc", form.value.logodoc);
      }
      if (param2 == "REMOVE") {
        store.commit("setLoadingStatus", true);
        ApiService.delete(
          `/api/companies/${param1}?force_delete=${param3}`
        ).then(({ data }) => {
          // console.log("CompaniesSection resp delete", data);
          const index = companiesTableData.value.findIndex(
            (x) => x.id === param1
          );
          store.commit("removeCompany", index);
          // console.log("CompaniesSection antes de ejecutar el get offices");
          ApiService.query(`/api/offices`, {
            params: {
              companies_groups: [data.id],
            },
          }).then(({ data }) => {
            // console.log("CompaniesSection response get offices", data);
            store.commit("resetOfficeCompany");
            store.commit("setOEdit", false);
            data.offices.forEach((x) => {
              store.commit("setOfficeCompany", {
                id: x.id,
                company_id: x.company.name,
                name: x.name,
                is_main: x.is_main !== 0,
                remarks: x.remarks,
              });
            });
          });
          store.commit("setLoadingStatus", false);
        });
      }
    };

    const onCancel = () => {
      form.value.id = undefined;
      form.value.company_group_id = companyGroup.value.id;
      form.value.entity_id = undefined;
      form.value.accounting_plan_id = undefined;
      form.value.currency_id = undefined;
      form.value.name = "";
      form.value.cif = "";
      form.value.website = "";
      form.value.logo = "";
      form.value.lang = "";
      form.value.logodoc = "";
      form.value.fiscal_data = "";
      form.value.code = "";
      logodoc.value = undefined;
      logo.value = undefined;
      store.commit("emptyAddresses");
      store.commit("setCEdit", false);
    };

    const onSubmit = handleSubmit(async (values) => {
      store.commit("setLoadingStatus", true);
      if (submitCompanyButton.value) {
        if (companyGroup.value.id !== undefined) {
          if (companyGroup.value.name !== "") {
            submitCompanyButton.value.setAttribute("data-kt-indicator", "on");
            const formData = new FormData();
            formData.append("company_group_id", companyGroup.value.id);
            formData.append(
              "entity_id",
              form.value.entity_id ? form.value.entity_id : undefined
            );
            formData.append(
              "accounting_plan_id",
              JSON.stringify(form.value.accounting_plan_id)
            );
            formData.append("name", form.value.name);
            formData.append("cif", form.value.cif);
            formData.append("fiscal_data", form.value.fiscal_data);
            formData.append("code", form.value.code);
            formData.append("currency_id", form.value.currency_id);
            formData.append("lang", form.value.lang);
            formData.append(
              "website",
              form.value.website ? form.value.website : ""
            );
            if (logo.value) {
              formData.append("logo", logo.value);
            }
            if (logodoc.value) {
              formData.append("logodoc", logodoc.value);
            }
            const addresses = [] as any;
            dataTable.value.forEach(function (addr) {
              const ati = [] as any;
              addr.address_types.forEach(function (atiVal) {
                if (atiVal.id) {
                  ati.push({
                    address_type_id: atiVal.id,
                  });
                } else {
                  ati.push({
                    address_type_id: atiVal,
                  });
                }
              });
              addresses.push({
                id: addr.id,
                company_id: addr.company_id,
                street: addr.street,
                number: addr.number,
                floor_number: addr.floor_number,
                door: addr.door,
                location_id: addr.location_id,
                zip_code: addr.zip_code,
                latitude: addr.latitude,
                longitude: addr.longitude,
                address_types: ati,
              });
            });
            // formData.append("addresses", addresses[0]);
            formData.append("addresses", JSON.stringify(addresses));
            formData.append("offices", "[]");
            if (form.value.id !== "") {
              formData.append("id", form.value.id);
            }
            // console.log("CompaniesSection form.value", form.value);
            await store.dispatch(Actions.SAVE_COMPANY, {
              payload: formData,
              itemId: form.value.id,
            });
            submitCompanyButton.value?.removeAttribute("data-kt-indicator");
            onCancel();
          } else {
            ElNotification({
              title: "Error",
              message: t("rcompanygroupname"),
              type: "error",
            });
          }
        } else {
          submitCompanyButton.value.setAttribute("data-kt-indicator", "on");
          store.commit("setCompanyItem", form.value);
          store.commit("setFromChild", true);
          emit("saveCompanyGroup", true);
          submitCompanyButton.value?.removeAttribute("data-kt-indicator");
        }
      }
      store.commit("setLoadingStatus", false);
    });
    const createAddress = () => {
      store.commit("createAddresses", {
        id: 0,
        entity_id: undefined,
      });
    };
    const saveCompany = (data) => {
      emit("saveCompanyGroup", data);
      store.commit("setFromChild", true);
    };
    const addressData = computed(() => store.getters.Addresses);
    const removeAddress = (i) => {
      addressData.value.forEach(function (value, index) {
        if (value.id === undefined) {
          addressData.value.splice(i, 1);
        } else {
          if (index === i) {
            addressData.value.splice(i, 1);
          }
        }
      });
    };

    return {
      editCompany,
      canShow,
      canUpdate,
      canCreate,
      canDelete,
      editOffice,
      submitCompanyButton,
      companiesTableData,
      officesTableData,
      header,
      headerOffice,
      isLoading,
      tableButtons,
      form,
      company,
      companyGroup,
      isNameRequired,
      isCifRequired,
      isCodeRequired,
      changeImage,
      removeImage,
      changeImageDoc,
      removeImageDoc,
      onNewClick,
      onNewOfficeClick,
      onCancel,
      onSubmit,
      actionsButton,
      createAddress,
      removeAddress,
      saveCompany,
    };
  },
});
