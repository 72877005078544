
import { Form, Field, ErrorMessage } from "vee-validate";
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import CompanySelect from "@/components/catalogs-select/CompanySelect.vue";
import UsersSelect from "@/components/catalogs-select/UsersSelect.vue";
import AccountingPlanSelect from "@/components/catalogs-select/AccountingPlanSelect.vue";
import AddressSectionGC from "@/components/companies/companies-group/AddressSectionGC.vue";
import CommunicationSectionGC from "@/components/companies/companies-group/CommunicationSectionGC.vue";
import { useI18n } from "vue-i18n";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import PortsSelect from "@/components/catalogs-select/PortsSelect.vue";
import Avatar from "@/components/avatar/avatar.vue";

interface FormOffice {
  id?: number | null;
  company_id: number | null;
  port_id: number | null;
  accounting_plan_id: number | null;
  name: string;
  is_main: boolean;
  logo: string;
  remarks: string;
  users: [];
  addresses: [];
  communications: [];
}

export default defineComponent({
  name: "OfficeTab",
  components: {
    Avatar,
    PortsSelect,
    Form,
    Field,
    ErrorMessage,
    CompanySelect,
    UsersSelect,
    AccountingPlanSelect,
    AddressSectionGC,
    CommunicationSectionGC,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const viewCheckBox = ref(true);
    const accountingPlans = ref([]);
    const editOffice = computed(() => store.getters.OOEdit);
    const officeItem = computed(() => store.getters.OfficeC);
    const editAddress = computed(() => store.getters.AOEdit);
    const editCommunication = computed(() => store.getters.COEdit);
    const companyGroupId = computed(() => store.getters.CompanyGroupId);
    const addressesTableData = computed(() => store.getters.COAddressOffice);
    const fromChild = computed(() => store.getters.UpdateFromChild);
    const address = computed(() => store.getters.AddressOC);
    const communicationsTableData = computed(
      () => store.getters.COCommunicationOffice
    );
    const submitOfficeButton = ref<HTMLElement | null>(null);
    const logo = ref(undefined) as any;
    const form = ref<FormOffice>({
      company_id: null,
      accounting_plan_id: null,
      name: "",
      port_id: null,
      is_main: false,
      logo: "",
      remarks: "",
      users: [],
      addresses: [],
      communications: [],
    });

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    watch(
      () => editOffice.value,
      (first) => {
        if (first && officeItem.value.id !== undefined) {
          form.value.id = officeItem.value.id;
          form.value.port_id = officeItem.value.port_id;
          form.value.company_id = officeItem.value.company_id;
          form.value.accounting_plan_id = officeItem.value.accounting_plan_id;
          form.value.name = officeItem.value.name;
          form.value.is_main = officeItem.value.is_main;
          form.value.logo = officeItem.value.logo;
          form.value.remarks = officeItem.value.remarks;
          form.value.users = officeItem.value.users.map((element, index) => {
            return element["id"];
          });
          form.value.addresses = officeItem.value.addresses;
        }
      }
    );

    onMounted(() => {
      form.value.id = officeItem.value.id;
      form.value.port_id = officeItem.value.port_id;
      form.value.company_id = officeItem.value.company_id;
      form.value.accounting_plan_id = officeItem.value.accounting_plan_id;
      form.value.name = officeItem.value.name;
      form.value.is_main = officeItem.value.is_main;
      form.value.logo = officeItem.value.logo;
      form.value.remarks = officeItem.value.remarks;
      form.value.users = officeItem.value.users.map((element, index) => {
        return element["id"];
      });
      form.value.addresses = officeItem.value.addresses;
    });

    const changeImage = (event) => {
      logo.value = event[0];
    };

    const removeImage = () => {
      logo.value = "";
    };

    const buildOffice = (data) => {
      const payload = {
        id: data.id,
        company_id: data.company.name,
        name: data.name,
        port_id: data.port_id,
        is_main: data.is_main !== 0,
        logo: data.logo,
        remarks: data.remarks,
      };
      store.commit("setOfficeCompany", payload);
      return payload;
    };

    const onCancel = () => {
      form.value.id = null;
      form.value.company_id = null;
      form.value.port_id = null;
      form.value.accounting_plan_id = null;
      form.value.name = "";
      form.value.is_main = false;
      form.value.logo = "";
      form.value.remarks = "";
      form.value.users = [];
      form.value.addresses = [];
      form.value.communications = [];
      logo.value = undefined;
      store.commit("setOfficeC", form.value);
      store.commit("cleanCOAddress");
      store.commit("cleanCOCommunication");
      store.commit("setOEdit", false);
    };

    const companyHasMainOffice = (company_id) => {
      ApiService.get(`/api/companies/${company_id}`).then(({ data }) => {
        data.offices.forEach((item) => {
          if (item.id != form.value.id && item.is_main == 1) {
            viewCheckBox.value = false;
            form.value.is_main = false;
          }
        });
      });
    };

    watch(
      () => form.value.company_id,
      (first) => {
        if (form.value.company_id) {
          viewCheckBox.value = true;
          companyHasMainOffice(first);
          getAccountingPlanByCompany(first);
        }
      }
    );

    watch(
      () => form.value.id,
      (first) => {
        if (form.value.company_id) {
          viewCheckBox.value = true;
          companyHasMainOffice(form.value.company_id);
        }
      }
    );

    const onSubmit = async (value) => {
      store.commit("setLoadingStatus", true);
      if (
        submitOfficeButton.value &&
        form.value.company_id &&
        form.value.name !== ""
      ) {
        submitOfficeButton.value.setAttribute("data-kt-indicator", "on");
        const formData = new FormData();
        formData.append(
          "company_id",
          form.value.company_id ? form.value.company_id.toString() : ""
        );
        formData.append(
          "port_id",
          form.value.port_id ? form.value.port_id.toString() : ""
        );
        formData.append(
          "accounting_plan_id",
          form.value.accounting_plan_id
            ? form.value.accounting_plan_id.toString()
            : ""
        );
        formData.append("name", form.value.name);
        formData.append("users", form.value.users.toString());
        formData.append("is_main", form.value.is_main ? "1" : "0");
        if (logo.value) {
          formData.append("logo", logo.value);
        }
        formData.append("remarks", form.value.remarks);
        formData.append(
          "addresses",
          store.getters.COAddressOffice.map((x) => x.id)
        );
        formData.append(
          "communications",
          store.getters.COCommunicationOffice.map((x) => x.id)
        );
        await store.dispatch(Actions.SAVE_COMPANY_GROUP_OFFICE, {
          payload: formData,
          itemId: officeItem.value.id,
        });
        if (fromChild.value) {
          store.commit("setFromChild", false);
          let temp = address.value;
          temp.office_id = officeItem.value.id;
          store.commit("updateCOAddress", temp);
          await store.dispatch(Actions.SAVE_COMPANY_GROUP_ADDRESS, {
            payload: temp,
            itemId: temp.id,
          });
          store.commit("setsaveForm", false);
          store.commit("setAddressOC", {
            id: null,
            office_id: officeItem.value.id,
            port_id: officeItem.value.port_id,
            company_id: officeItem.value.company_id,
            accounting_plan_id: officeItem.value.accounting_plan_id,
            entity_id: null,
            contact_id: null,
            address_types: null,
            street: "",
            number: "",
            floor_number: "",
            door: "",
            location_id: null,
            zip_code: "",
            latitude: "",
            longitude: "",
          });
        }
        submitOfficeButton.value.removeAttribute("data-kt-indicator");
        store.commit("setOEdit", false);
        onCancel();
        store.commit("setLoadingStatus", false);
      } else {
        ElNotification({
          title: "Error",
          message: t("rcompanyougrpoffice"),
          type: "error",
        });
        store.commit("setLoadingStatus", false);
      }
    };

    const getAccountingPlanByCompany = (company_id) => {
      ApiService.get(`/api/companies/${company_id}`).then(({ data }) => {
        accountingPlans.value = data.accounting_plans;
      });
    };

    return {
      form,
      editOffice,
      officeItem,
      viewCheckBox,
      submitOfficeButton,
      addressesTableData,
      communicationsTableData,
      editAddress,
      editCommunication,
      companyGroupId,
      accountingPlans,
      getAccountingPlanByCompany,
      onCancel,
      isNameRequired,
      changeImage,
      removeImage,
      onSubmit,
      companyHasMainOffice,
    };
  },
});
