
import { computed, ref } from "vue";
import CommunicationMediaTypesSelect from "@/components/catalogs-select/CommunicationMediaTypesSelect.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";

export default {
  name: "CommunicationSectionGC",
  components: {
    TableBasicSelect,
    CommunicationMediaTypesSelect,
    Form,
    Field,
    ErrorMessage,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const submitCommunicationButton = ref<HTMLElement | null>(null);
    const communicationsTableData = computed(
      () => store.getters.COCommunicationOffice
    );
    const office = computed(() => store.getters.OfficeC);
    const editCommunication = computed(() => store.getters.COEdit);
    const header = ref([
      {
        column: "imedia",
        key: "media_name",
      },
      { column: "ivalue", key: "value" },
      { column: "iremarks", key: "remark" },
    ]);
    const isLoading = ref(false);
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Duplicar" as never,
        type: "DUPLICATE-2" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,
    ]);
    const form = ref({
      id: undefined,
      media_id: undefined,
      office_id: office.value.id,
      entity_id: undefined,
      contact_id: undefined,
      value: "",
      remark: "",
    });

    const isValueRequired = (value) => {
      if (!value) {
        return t("rvalue");
      }
      return true;
    };

    const onNewClick = () => {
      form.value.office_id = office.value.id;
      store.commit("setCOEdit", true);
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        ApiService.get(`/api/communications/${param1}`).then(({ data }) => {
          form.value.id = data.id;
          form.value.office_id = data.office_id;
          form.value.media_id = data.media_id;
          form.value.value = data.value;
          form.value.remark = data.remark;
          store.commit("setCOEdit", true);
        });
      }
      if (param2 == "DUPLICATE") {
        store.commit("setLoadingStatus", true);
        const item = communicationsTableData.value.filter(
          (x) => x.id === param3
        );
        let payload = {
          media_id: item[0].media_id,
          office_id: item[0].office_id,
          value: item[0].value,
          remark: item[0].remark,
        };
        ApiService.post("/api/communications", payload)
          .then(({ data }) => {
            submitCommunicationButton.value?.removeAttribute(
              "data-kt-indicator"
            );
            store.commit("setCOCommunication", {
              id: data.id,
              office_id: data.office_id,
              media_id: data.media_id,
              value: data.value,
              remark: data.remark,
              media_name: data.media.name,
            });
            store.commit("setLoadingStatus", false);
          })
          .catch(() => {
            submitCommunicationButton.value?.removeAttribute(
              "data-kt-indicator"
            );
            store.commit("setLoadingStatus", false);
          });
      }
      if (param2 == "REMOVE") {
        store.commit("setLoadingStatus", true);
        ApiService.delete(
          `/api/communications/${param1}?force_delete=${param3}`
        )
          .then(({ data }) => {
            const index = communicationsTableData.value.findIndex(
              (x) => x.id === param1
            );
            store.commit("setsaveForm", false);
            store.commit("removeCOCommunication", index);
            store.commit("setLoadingStatus", false);
          })
          .catch(() => {
            submitCommunicationButton.value?.removeAttribute(
              "data-kt-indicator"
            );
            store.commit("setLoadingStatus", false);
          });
      }
    };

    const onCancel = () => {
      form.value.id = undefined;
      form.value.office_id = office.value.id;
      form.value.media_id = undefined;
      form.value.value = "";
      form.value.remark = "";
      store.commit("setCOEdit", false);
      store.commit("setsaveForm", false);
    };

    const onSubmit = () => {
      store.commit("setLoadingStatus", true);
      if (!form.value.id) {
        ApiService.post("/api/communications", form.value).then(({ data }) => {
          store.commit("setCOCommunication", {
            id: data.id,
            office_id: data.office_id,
            media_id: data.media_id,
            value: data.value,
            remark: data.remark,
            media_name: data.media.name,
          });
          store.commit("setsaveForm", false);
          store.commit("setLoadingStatus", false);
        });
      } else {
        ApiService.put(`/api/communications/${form.value.id}`, form.value).then(
          ({ data }) => {
            store.commit("setCOCommunication", {
              id: data.id,
              office_id: data.office_id,
              media_id: data.media_id,
              value: data.value,
              remark: data.remark,
              media_name: data.media.name,
            });
            const index = communicationsTableData.value.findIndex(
              (x) => x.id === form.value.id
            );
            store.commit("removeCOCommunication", index);
            store.commit("setsaveForm", false);
            store.commit("setLoadingStatus", false);
          }
        );
      }
      store.commit("setCOEdit", false);
      store.commit("setsaveForm", false);
    };

    return {
      editCommunication,
      submitCommunicationButton,
      communicationsTableData,
      header,
      isLoading,
      tableButtons,
      form,
      office,
      isValueRequired,
      onNewClick,
      onCancel,
      onSubmit,
      actionsButton,
    };
  },
};
