
import {computed, defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
import { useI18n } from "vue-i18n";
import OfficeTab from "@/components/companies/companies-group/OfficeTab.vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import TableBasicSelect from "@/components/tables/TableBasicSelect.vue";

export default defineComponent({
  name: "OfficeList",
  components: {
    TableBasicSelect,
    OfficeTab,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const companyGroupId = computed(() => store.getters.CompanyGroupId);
    const editOffice = computed(() => store.getters.OOEdit);
    let officesTableData = computed(() => store.getters.OfficeCompaniesList);
    const filtered = ref(officesTableData.value);
    const isLoading = ref(false);
    const search = ref("");
    const company_id = "";
    const header = ref([
      { column: "icompany", key: "company_id" },
      { column: "iname", key: "name" },
      { column: t("iremarks"), key: "remarks" },
      { column: t("iismain"), key: "is_main", type: "check" },
    ]);
    const tableButtons = ref([
      {
        name: "Editar" as never,
        type: "EDIT" as never,
        color: "primary" as never,
      } as never,
      {
        name: "Eliminar" as never,
        type: "REMOVE" as never,
        color: "primary" as never,
      } as never,
    ]);

    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };

    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canCreate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "offices: create").length >
          0
        );
      }
      return false;
    });

    const canShow = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "offices: show").length > 0
        );
      }
      return false;
    });

    const canUpdate = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "offices: update").length >
          0
        );
      }
      return false;
    });

    const canDelete = computed(() => {
      if (JSON.stringify(user.value) !== "{}") {
        return (
          permissions.value.filter((x) => x.name === "offices: delete").length >
          0
        );
      }
      return false;
    });

    const onNewClick = () => {
      store.commit("setOEdit", !editOffice.value);
    };

    const buildOffice = (data) => {
      const payload = {
        id: data.id,
        company_id: data.company_id,
        name: data.name,
        port_id: data.port_id,
        is_main: data.is_main !== 0,
        logo: data.logo,
        remarks: data.remarks,
        users: data.users,
        accounting_plan_id: data.accounting_plan.id,
      };
      store.commit("setOfficeC", payload);
      data.addresses.forEach((x) => {
        store.commit("setCOAddress", {
          id: x.id,
          office_id: x.office_id,
          address_types: x.address_types,
          street: x.street,
          number: x.number,
          floor_number: x.floor_number,
          door: x.door,
          location_id: x.location_id,
          location: x.location,
          latitude: x.latitude,
          longitude: x.longitude,
          zip_code: x.zip_code,
        });
      });
      data.communication.forEach((x) =>
        store.commit("setCOCommunication", {
          id: x.id,
          media_id: x.media_id,
          office_id: x.office_id,
          value: x.value,
          remark: x.remark,
          media_name: x.media.name,
        })
      );
      store.commit("setOEdit", true);
      return payload;
    };

    const actionsButton = (param1, param2, param3) => {
      if (param2 == "EDIT") {
        store.commit("setLoadingStatus", true);
        ApiService.get(`/api/offices/${param1}`).then(({ data }) => {
          buildOffice(data);
        });
      }

      if (param2 == "DUPLICATE") {
        const item = officesTableData.value.find((x) => x.id === param3);
        const formData = new FormData();
        formData.append("company_id", item.company_id);
        formData.append("name", item.name);
        formData.append("is_main", item.is_main ? "1" : "0");
        formData.append("logo", item.logo);
        formData.append("remarks", item.remarks);
        ApiService.postAttach("/api/offices", formData).then(({ data }) => {
          const payload = {
            id: data.id,
            company_id: data.company_id,
            name: data.name,
            is_main: data.is_main !== 0,
            logo: data.logo,
            remarks: data.remarks,
            users: data.users,
            addresses: data.addresses,
            communications: data.communications,
          };
          store.commit("setOfficeC", payload);
        });
      }
      if (param2 == "REMOVE") {
        store.commit("setLoadingStatus", true);
        ApiService.delete(`/api/offices/${param1}?force_delete=${param3}`).then(
          ({ data }) => {
            const index = officesTableData.value.findIndex(
              (x) => x.id === param1
            );
            store.commit("removeOfficeCompany", index);
            store.commit("setLoadingStatus", false);
          }
        );
      }
    };

    onUnmounted(() => {
      // store.commit("resetOfficeSelectModule");
    });

    watch(
      () => editOffice.value,
      (first) => {
        if (!first) {
          store.commit("resetOfficeCompany");

          store.commit("setLoadingStatus", true);
          ApiService.query(`/api/offices`, {
            params: {
              companies_groups: [companyGroupId.value],
              per_page: 10000,
            },
          }).then(({ data }) => {
            data.offices.forEach((x) => {
              store.commit("setOfficeCompany", {
                id: x.id,
                company_id: x.company.name,
                can_it_be_deleted: x.can_it_be_deleted,
                name: x.name,
                is_main: x.is_main !== 0,
                remarks: x.remarks,
              });
            });
            store.commit("setLoadingStatus", false);
          });
        }
      }
    );

    const onSearch = () => {
      filtered.value = officesTableData.value.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    return {
      onSearch,
      search,
      filtered,
      header,
      canShow,
      canCreate,
      canUpdate,
      canDelete,
      isLoading,
      tableButtons,
      companyGroupId,
      editOffice,
      officesTableData,
      company_id,
      onNewClick,
      actionsButton,
    };
  },
});
