<template>
  <div class="tabs-container">
    <div v-if="false" class="d-flex flex-wrap flex-sm-nowrap mb-3">
      <div class="flex-grow-1">
        <div class="d-flex flex-wrap flex-stack">
          <div class="d-flex flex-column flex-grow-1 pe-8">
            <div class="d-flex flex-wrap">
              <div
                class="border border-gray-100 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
              >
                <div class="d-flex align-items-center">
                  <span class="svg-icon svg-icon-success me-2"> </span>
                  <div
                    class="fs-2 fw-bolder text-white"
                    v-html="groupCompanies()"
                  ></div>
                </div>
                <div class="fw-bold fs-6 text-gray-400">
                  {{ $t("companiesGroup") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          id="companyGroup"
          class="nav-link active"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('companyGroup')"
          >{{ $t("companiesGroup") }}
        </a>
      </li>
      <li class="nav-item" v-if="canListOffices || canCreateOffice || (canShowOffice && canUpdateOffice)">
        <a
          v-if="companyGroupId !== undefined"
          id="office"
          class="nav-link"
          data-bs-toggle="tab"
          href="#"
          @click="handleTabClick('office')"
          >{{ $t("ioffices") }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onUpdated } from "vue";

export default {
  name: "CompaniesGroupTab",
  emits: ["changeTab"],

  setup(props, { emit }) {
    const store = useStore();
    const companyGroup = computed(() => store.getters.CompanyGroup);
    const companyGroupId = computed(() => store.getters.CompanyGroupId);
    const companyGroupTab = computed(() => store.getters.CompanyGroupTabItem);

    const groupCompanies = () => {
      const companies = companyGroup.value.companies.map((x) => {
        const name = `${x["name"]} <br>`;
        return name;
      });
      const result = companies
        .filter((x, index) => {
          return companies.indexOf(x) === index;
        })
        .join("");
      return result;
    };

    const handleTabClick = (tab) => {
      store.commit("setLoadingStatus", true);
      emit("changeTab", tab);
    };
    const permissionsTemp = localStorage.getItem("permissions");
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canListOffices = computed(() => {
      return (
          permissions.value.filter((x) => x.name === "offices: index").length >
          0
      );
    });
    const canShowOffice = computed(() => {
      return (
          permissions.value.filter((x) => x.name === "offices: show").length >
          0
      );
    });
    const canUpdateOffice = computed(() => {
      return (
          permissions.value.filter((x) => x.name === "offices: update").length >
          0
      );
    });
    const canCreateOffice = computed(() => {
      return (
          permissions.value.filter((x) => x.name === "offices: create").length >
          0
      );
    });
    onUpdated(() => {
      if ("tab" in companyGroupTab.value) {
        const overview = document.getElementById("overview");
        const tab = document.getElementById(companyGroupTab.value.tab);
        tab.classList.add("active");
        overview.classList.remove("active");
        emit("changeTab", companyGroupTab.value.tab);
        store.commit("removeMaritimeFileTab");
      }
    });

    return {
      companyGroup,
      companyGroupId,
      groupCompanies,
      handleTabClick,
      canListOffices,
      canShowOffice,
      canCreateOffice,
      canUpdateOffice,
    };
  },
};
</script>
