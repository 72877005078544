
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute, useRouter } from "vue-router";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";
import { Form, Field, ErrorMessage } from "vee-validate";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import CompaniesGroupTab from "@/components/companies/companies-group/CompaniesGroupTab.vue";
import CompaniesSection from "@/components/companies/companies-group/CompaniesSection.vue";
import OfficeList from "@/components/companies/companies-group/OfficeList.vue";
//import Loading from "@/components/Loading.vue";

interface FormCompanyGroup {
  id?: string;
  name: string;
}

export default defineComponent({
  name: "CompaniesGroupCU",
  components: {
    //Loading,
    Form,
    Field,
    ErrorMessage,
    CompaniesGroupTab,
    CompaniesSection,
    OfficeList,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const companyItem = computed(() => store.getters.Company);
    const companyGroup = computed(() => store.getters.CompanyGroup);
    const fromChild = computed(() => store.getters.UpdateFromChild);
    const dataTable = computed(() => store.getters.Addresses);
    const tab = ref("companyGroup");
    const u = localStorage.getItem("user") as any;
    const user = {
      value: JSON.parse(u),
    };
    // activities log store
    const recently_activities = computed(
      () => store.getters.RecentlyActivities
    );
    const form = ref<FormCompanyGroup>({
      id: "",
      name: "",
    });
    const permissionsTemp = localStorage.getItem("permissions") as any;
    const permissions = {
      value: JSON.parse(permissionsTemp),
    };

    const canListOffices = computed(() => {
      return (
        permissions.value.filter((x) => x.name === "offices: index").length > 0
      );
    });

    const canUpdate = computed(() => {
      return (
          permissions.value.findIndex((x) => x.name === "companies group: update") !== -1
      );
    });
    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const selectedTab = (value) => {
      tab.value = value;
      setTimeout(() => {
        store.commit("setLoadingStatus", false);
      }, 3000);
    };

    const onSubmit = async (values) => {
      store.commit("setLoadingStatus", true);
      if (submitButton.value && form.value.name !== "") {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        await store.dispatch(Actions.SAVE_COMPANY_GROUP, {
          payload: form.value,
          itemId: form.value.id,
        });
        submitButton.value?.removeAttribute("data-kt-indicator");
        if (fromChild.value) {
          store.commit("setFromChild", false);
          const formData = new FormData();
          formData.append("company_group_id", companyGroup.value.id);
          formData.append("entity_id", companyItem.value.entity_id);
          formData.append(
            "accounting_plan_id",
            JSON.stringify(companyItem.value.accounting_plan_id)
          );
          formData.append("name", companyItem.value.name);
          formData.append("cif", companyItem.value.cif);
          formData.append(
            "website",
            companyItem.value.website ? companyItem.value.website : ""
          );
          formData.append(
            "logo",
            companyItem.value.logo ? companyItem.value.logo : ""
          );
          formData.append(
            "logodoc",
            companyItem.value.logodoc ? companyItem.value.logodoc : ""
          );
          const address = [] as any;
          const address_type_id = [] as any;
          dataTable.value.forEach(function (value) {
            value.address_type_id.forEach(function (ati) {
              address_type_id.push({
                address_type_id: ati,
              });
            });
            address.push({
              street: value.street,
              number: value.number,
              floor_number: value.floor_number,
              door: value.door,
              location_id: value.location_id,
              zip_code: value.zip_code,
              latitude: value.latitude,
              longitude: value.longitude,
              address_types: address_type_id,
            });
          });
          formData.append("addresses", JSON.stringify(address));
          formData.append(
            "offices",
            companyItem.value.offices ? companyItem.value.offices : ""
          );
          if (companyItem.value.id !== "") {
            formData.append("id", companyItem.value.id);
          }
          await store.dispatch(Actions.SAVE_COMPANY, {
            payload: formData,
            itemId: form.value.id,
          });
        }
        if (companyGroup.value.id !== -1) {
          router.push({
            name: "companiesGroupUpdate",
            params: { id: companyGroup.value.id },
          });
        }
        store.commit("setLoadingStatus", false);
      } else {
        ElNotification({
          title: "Error",
          message: t("rcompanygroupname"),
          type: "error",
        });
        store.commit("setLoadingStatus", false);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs(
        form.value.id !== undefined
          ? "companiesGroupUpdate"
          : "companiesGroupCreate",
        [
          {
            route: "/companies/companiesgroup/index",
            label: "companiesGroup",
          },
        ]
      );

      if ("id" in route.params) {
        store.commit("setLoadingStatus", true);
        setTimeout(function () {
          store.commit("emptyAddresses");
          store.commit("resetOfficeSelectModule");
          store.commit("setCGItem", route.params.id);
          ApiService.get(`/api/companiesGroups/${route.params.id}`)
            .then(({ data }) => {
              form.value.id = data.id;
              form.value.name = data.name;
              store.commit("setCompanyGroup", form.value);
              data.companies.forEach((x) => {
                store.commit("setCompanyG", {
                  id: x.id,
                  addresses: x.addresses,
                  accounting_plans: x.accounting_plans,
                  company_group_id: x.company_group_id,
                  entity_id: x.entity_id,
                  entity_name: x.entity ? x.entity.name : "",
                  name: x.name,
                  cif: x.cif,
                  website: x.website ? x.website : "",
                  logo: x.logo,
                  logodoc: x.logodoc,
                });
              });
            })
            .catch(() => {
              submitButton.value?.removeAttribute("data-kt-indicator");
            });
          if (canListOffices.value) {
            ApiService.query(`/api/offices`, {
              params: {
                companies_groups: [route.params.id],
                per_page: 10000,
                users: [user.value.id],
              },
            }).then(({ data }) => {
              data.offices.forEach((x) => {
                store.commit("setOfficeCompany", {
                  id: x.id,
                  company_id: x.company  ? x.company.name : "",
                  can_it_be_deleted: x.can_it_be_deleted,
                  name: x.name,
                  is_main: x.is_main !== 0,
                  remarks: x.remarks,
                });
              });
              store.commit("setLoadingStatus", false);
            });
          }
        }, 2000);
      }
    });

    onUnmounted(() => {
      store.commit("resetOfficeSelectModule");
    });

    return {
      form,
      submitButton,
      tab,
      isNameRequired,
      selectedTab,
      onSubmit,
      canListOffices,
      canUpdate,
    };
  },
});
